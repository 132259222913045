@import "../../assets/styles/_variables.scss";

// container for heading, subheading, button etc.
.container {
    padding: 100px 0;
}
.title-product {
    min-width: 232px;
    // margin-left: 50px;
    max-width: 570px;

    @media (max-width: 570px) {
        padding: 0 20px;
        margin: 0px auto;
        margin-bottom: 0px;
    }

    h1 {
        text-align: left;
        margin: 0;
        padding: 0;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 300;
        font-size: 34px;
        color: $font-primary;
        max-width: 500px;
        @media (max-width: 500px) {
            font-size: 28px;
        }

        .important {
            // font-size: 3.8rem;
            letter-spacing: -2.7px;
            font-size: 72px;
            font-weight: 600;
            @media (max-width: 500px) {
                font-size: 60px;
            }
        }
    }

    // container for icon and small text
    .logo {
        margin-top: 80px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        @media (max-width: 1050px) {
            justify-content: center;
        }
        img {
            max-height: 36px;
            margin-right: 10px;
        }
        p {
            margin: 0;
            font-size: 16px;
            text-align: left;
            color: $primary;
            width: 130px;
        }
    }

    .buttons {
        padding: 0;
        display: flex;
        @media (max-width: 1050px) {
            justify-content: center;
        }
    }

    .button-large {
        border: 1.8px solid red;
        color: $font-primary;
        font-size: 24px;
        padding: 8px 8px;
        margin: 0;
        background: red;
        border-radius: $button-radius-large;
        letter-spacing: 0.1rem;
    }

    .button-large:hover {
        // box-shadow: 5px 5px $primary;
        color: black;
        // background-color: $secondary-quarter;
        cursor: pointer;
    }
}

// container for image
.product-image {
    min-width: 232px;
    display: flex;
    justify-content: center;

    @media (max-width: 1050px) {
        padding-top: 50px;
    }
    @media (min-width: 655px) {
        width: 500px;
    }

    img {
        height: 440px;
        box-shadow: -5px 5px 15px rgba($color: black, $alpha: 0.3);
    }
}

// background style starts here
.solid {
    height: 50px;
    background-color: $background;
}

hr {
    width: 0;
    height: 3px;
    background: $primary;
    border: $primary;
    margin: 0px;
    transition: width 400ms ease-out;
}
