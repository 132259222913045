@import "./variables";

.projects {
    color: $background;
    background-color: $background-alt;
}
.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}

p {
    text-align: center;
}

.card {
    border-radius: $card-radius;
    max-width: 350px;
    height: 350px;
    box-shadow: 0px 0px 15px rgba($color: black, $alpha: 0.2);
    margin: 15px;
    margin-bottom: 50px;
    color: $font-primary;
    // background-color: rgba($color: $background, $alpha: 0.8);
    background: linear-gradient(135deg, #3d4b66 0%, #1c222e 100%);

    h4 {
        font-size: 3.5rem;
        // text-transform: uppercase;
        margin: 0px 5px;
        color: #fff;
        text-align: center;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
        font-family: Open Sans;
        font-weight: 300;

        @media (max-width: 350px) {
            font-size: 3rem;
        }
    }

    .card-thumbnail {
        // opacity: 0.8;
        margin: 20px 20px 5px;
        max-height: 500px;
        overflow: hidden;

        .card-image {
            // width: 100%;
            margin-left: auto;
            margin-right: auto;
            max-height: 100px;
            display: block;
            object-fit: contain;
        }
    }

    a {
        text-decoration: none;
    }

    p {
        font-size: 0.7rem;
        color: #fff;
        // margin: 0px 25px;
        text-align: center;
        font-family: Open Sans;
    }
    .button-text {
        color: $font-secondary;
    }

    .blurb {
        font-size: 1.3rem;
        font-weight: 100;
        margin: 0px 30px;
        padding-top: 10px;
        padding-bottom: 5px;
        border-top: 2px solid $font-primary;
    }

    .button-fake {
        border: 1.8px solid $primary;
        color: $primary;
        font-size: 1.1rem;
        padding: 0px 10px 2px;
        margin: 0;
        background: none;
        border-radius: $button-radius;
        letter-spacing: 0.1rem;
    }
    .buttons {
        display: flex;
        justify-content: center;
    }
}

.card:hover {
    box-shadow: 0px 0px 15px rgba($color: rgb(0, 0, 0), $alpha: 0.8);
    transition-duration: 0.2s;
    cursor: pointer;
    transform: scale(1.03);
    h4 {
        opacity: 1;
    }

    .tag {
        color: $font-primary;
        border: 1px solid transparent;
        background-color: $gradient-bottom;
    }

    // .card-thumbnail {
    //     opacity: 1;
    // }
}
