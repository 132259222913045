@import "./_variables.scss";

.image {
    flex-grow: 2;
    height: 300px;
    min-width: 250px;
    overflow: hidden;
    border-radius: 25px;
    margin: 0 25px;
    margin-top: 50px;
    background-image: linear-gradient($gradient-top, $gradient-bottom);

    .photo {
        overflow: hidden;
        width: 250px;
        object-fit: cover;
    }
}

.image:hover {
    background-color: $secondary;
    transition: background-color 200ms ease;
}

.title {
    margin-left: calc(50% - 250px);
    margin-top: 50px;
    min-width: 232px;
    p {
        text-align: left;
    }
    @media (max-width: 570px) {
        padding: 0 40px;
        margin: 0px auto;
        margin-bottom: 0px;
    }

    h1 {
        margin-top: 20px;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .important {
        color: $primary;
        font-size: 3.8rem;
        font-weight: 800;
        }

        .important:hover hr{
            width: 100%;
        }
    }

    p {
        justify-content: left;
        padding-bottom: 20px;
        color: $font-secondary;
    }
}

.buttons {
    display: flex;
    justify-content: space-between;
}

hr {
    width: 0;
    height: 3px;
    background: $primary;
    border: $primary;
    margin: 0px;
    transition: width 400ms ease-out;
}