@import "./variables";

.nav-contianer {
    background-color: rgb(27, 27, 27);
    width: 100%;
    height: 50px;
    position: fixed;
    z-index: 1;
}
.nav-bar {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    // background-color: #161616;
}

li {
    // float: right;
}

li a {
    display: block;
    color: white;
    // text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

/* Change the link color to #111 (black) on hover */
li a:hover {
    background-color: $gradient-top;
}

li a.active {
    color: $primary;
    font-weight: 600;
}
