// colours
$background: #1c222e;
$background-alt: #ececec;

$primary-highlight: rgb(25, 150, 255);
$primary: rgb(75, 165, 255);
$secondary: rgba(56, 108, 187, 1);
$secondary-half: rgba(56, 108, 187, 0.5);
$secondary-quarter: rgba(56, 108, 187, 0.25);

$button-colour: #000000;
$gradient-top: rgb(71, 106, 158);
$gradient-bottom: rgba(35, 47, 56, 0.5);

// font colours
$font-primary: rgb(250, 250, 250);
$font-secondary: rgb(185, 185, 185);

// border radius
$button-radius: 3px;
$button-radius-large: 5px;
$card-radius: 15px;
