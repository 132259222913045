@import "../../assets/styles/_variables.scss";

.hero-display {
    color: $background;
    background-color: $background-alt;
}
.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}

.hero-display h1 {
    font-weight: 400;
    font-size: 48px;
}

.pannel-hero {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 500px;
    margin-bottom: 50px;

    @media (max-width: 1200px) {
        width: 400px;
    }
    // @media (max-width: 800px) {
    //     width: 350px;
    // }
}

.product-text {
    width: fit-content;
    // padding: 50px, 0px;
    div {
        font-size: 1.5rem;
        line-height: 36px;
        font-family: "Open Sans";
        font-weight: 300;
        // padding-left: 100px;

        // tick icon
        img {
            height: 20px;
            width: 20px;
            margin-right: 10px;
        }
    }
}

.graphic-image {
    min-width: 232px;

    // display: flex;
    // justify-content: center;

    @media (max-width: 1050px) {
        // padding-top: 50px;
    }
    @media (min-width: 655px) {
        // width: 500px;
    }
    img {
        height: 250px;
        // box-shadow: -5px 5px 15px rgba($color: black, $alpha: 0.3);
    }
}
.graphic-image-SB img {
    height: 150px;
    // @media(max-width: 800px) {
    //     padding-top: 50px;
    //     height: 160px;
    // }
    @media (max-width: 400px) {
        height: 125px;
    }
}

.smartbuilder-image img {
    height: 180px;
    padding-top: 25px;

    @media (max-width: 800px) {
        padding-top: 50px;
        height: 160px;
    }
    @media (max-width: 400px) {
        height: 125px;
    }
}
