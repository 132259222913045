@import "./variables";

html {
    scroll-behavior: smooth;
    font-size: 0.9rem;
}

.App {
    //   font-family: Helvetica, sans-serif;
    background-color: $background;
    color: whitesmoke;
    width: 100%;
}

nav {
    // hide nav bar
    // display: none;
}

.heading {
    text-align: center;
    // font-size: 2.5rem;
    padding-top: 75px;
    // margin-top: 0px;
    font-weight: 300;
    font-size: 36px;
}

.sub-heading {
    padding-top: 50px;
    text-align: center;
}

.body-section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 300px;
    // padding: 50px 0;
    // margin-left: 120px; // nav bar width
}

.button-large {
    border: 1.8px solid $primary;
    color: $primary;
    font-size: 1.1rem;
    padding: 8px 8px;
    margin: 0;
    background: none;
    border-radius: $button-radius;
    letter-spacing: 0.1rem;
}

.button-large:hover {
    // box-shadow: 5px 5px $primary;
    background-color: $secondary-quarter;
    cursor: pointer;
}

p {
    color: $font-secondary;
}

.triangle-down {
    width: 0;
    height: 0;
    border-left: 50vw solid transparent;
    border-right: 50vw solid transparent;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    border-top: 60px solid $background;
    top: 60px;
    @media (max-width: 450px) {
        border-top: 30px solid $background;
        top: 60px;
    }
}

.testimonials {
    padding-bottom: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    scale: 0.8;
    opacity: 0;
    p {
        font-size: 1.5rem;
        line-height: 36px;
        font-family: "Open Sans";
        font-weight: 300;
        span {
            font-style: italic;
        }
        @media (max-width: 550px) {
            font-size: 1.4rem;
            line-height: 30px;
            max-width: 250px;
        }
    }
}

.testimonials {
    animation: fade-in linear forwards;
    animation-timeline: view();
    animation-range: 150px 500px;
}

// .pannel-hero {
//     scale: 0.8;
//     opacity: 0;
//     animation: fade-in linear forwards;
//     animation-timeline: view();
//     animation-range: 250px 900px;
// }

@keyframes fade-in {
    to {
        scale: 1;
        opacity: 1;
    }
}
