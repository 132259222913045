@import "./variables";

.contact {
    h1 {
        margin-bottom: 0;
    }
}
.contact-form {
    max-width: 550px;
    padding: 0;
    margin: 0 20px;
    flex-grow: 2;

    .input {
        background-color: $secondary-half;
        color: $font-primary;
        display: flex;
        padding: 10px 5px;
        margin: 5px 5px;
        border: none;
        border-radius: $button-radius;
    }

    .form-text {
        display: flex;

        .text {
            width: 50%;
        }
    }

    .subject,
    .message {
        width: 100%;
    }

    #send-message {
        margin: 10px 5px;
    }
}

.info {
    // width: 350px;
    margin: 0 25px;
}

.paragraph {
    text-align: justify;
    text-justify: inter-word;
}

.icon-list {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    min-width: 250px;
}

.icon {
    // min-width: 80px;
    // padding-top: 10px;
    // padding-bottom: 0;
    // border-radius: $button-radius;
    // border: 2px solid $primary;
    margin: 20px;
    color: $primary;
    text-decoration: none;
    svg {
        margin: 0;
        padding: 0;
        height: 2.8rem;
    }
}

// Icon links change on hover
a.icon:hover {
    color: $primary-highlight;
    transform: scale(1.07);
}
