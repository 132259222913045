@import "./variables";

.about {
    h2:first-of-type {
        margin-top: 0;
    }
}

#about-image {
    max-width: 450px;
    margin-top: 0px;

    .photo {
        height: 500px;
        width: 300px;
        position: relative;
        top: -30px;
        left: -10px;
    }
}

.icon-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    min-width: 250px;

    hr {
        display: none;

        @media (max-width: 359px) {
            display: block;
            width: 100%;
            height: 0;
        }
    }

    .icon {
        color: $primary;
        text-decoration: none;

        p {
            color: $primary;
        }
        :hover {
        }
    }

    svg {
        margin: 0;
        padding: 0;
        height: 2.8rem;
    }
}

.skills {
    max-width: 400px;
    margin: 0 25px;

    .paragraph {
        text-align: justify;
        text-justify: inter-word;
    }
}
