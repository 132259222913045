@import "../../assets/styles/_variables.scss";

.pannel {
    width: 750px;
    @media (max-width: 800px) {
        width: 450px;
    }
    @media (max-width: 500px) {
        width: 350px;
    }
    // display: flex;
    // justify-content: center;
    hr {
        margin-top: 20px;
        width: 100%;
        height: 2px;
        background: $background;
    }
    h2 {
        text-align: left;
        font-weight: 400;
        padding-top: 0px;
        margin-top: 10px;
        margin-bottom: 0px;
    }
    h3 {
        font-weight: 300;
        font-size: 24px;
        margin-top: 5px;
    }
}

.screenshot-image {
    display: flex;
    justify-content: center;
    img {
        // height: 480px;
        width: 750px;
        // background-color: grey;
        box-shadow: 0px 0px 15px rgba($color: black, $alpha: 0.3);
        @media (max-width: 800px) {
            width: 450px;
        }
        @media (max-width: 500px) {
            width: 350px;
        }
        @media (max-width: 400px) {
            width: 300px;
        }
    }
}

.video {
    width: 750px;
    height: 422px;
    @media (max-width: 800px) {
        width: 450px;
        height: 260px;
    }
    @media (max-width: 500px) {
        width: 350px;
        height: 200px;
    }
    @media (max-width: 350px) {
        width: 320px;
        height: 180px;
    }
}
